import React from 'react';
import { css } from '../../styled-system/css';
import Navbar from '../components/recrutement/Navbar';
import Footer from '../components/recrutement/Footer';
import ScrollToTop from '../components/ScrollToTop';
import Cookie from '../components/Cookie';

interface LayoutProps {
  children: React.ReactNode;
}

const HeaderAndFooterLayout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div className={css({ minHeight: '100vh', })}>
      <Navbar />
      {children}
      <Cookie isRecrutement={true} />
      <Footer />
      <ScrollToTop color={"#006BFF"} />
    </div>
  );
};

export default HeaderAndFooterLayout;
