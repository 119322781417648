import React from 'react'
import { css } from '../../../styled-system/css'

const Footer = () => {
    return (
        <footer
            className={css({
                display: 'flex',
                flexDir: 'column',
                backgroundColor: '#171F3D',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                padding: '1rem 0',
            })}
        >
            <p className={css({
                fontFamily: "Assistant !",
                fontWeight: 300,
                fontSize: '1rem',
                lineHeight: "1",
                color: "#fff",
            })}>&copy; 2024 Digit Universe. Tous droits réservés</p>
        </footer>
    );
}

export default Footer;