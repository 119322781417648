import React from 'react'
import { css } from '../../../styled-system/css'
// @ts-expect-error
import Logo from '../../images/logo_w.svg';
import { Link } from 'gatsby'
import { useLocation } from '@reach/router';

export default function RecHeader() {
    const contentPath = useLocation().pathname;

    return (
        <nav
            className={css({
                background: '#171F3D',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                position: 'sticky',
                top: 0,
                zIndex: 999999,
            })}
        >
            <div className={css({
                width: '70%',
                lgDown: {
                    width: '90%',
                },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            })}>
                <div className={css({
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'right',
                    alignItems: 'center',
                    lgDown: {
                        justifyContent: 'center',
                    },
                })}>
                    <div className={css({
                        width: 'calc(50% + 5rem)',
                        lgDown: {
                            width: '100%',
                        },
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',

                    })}>
                        <Link to={"/recrutement"} className={css({
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            gap: "0.25rem",
                            padding: "0.5rem",
                            alignItems: 'center',
                            width: "10rem",
                            "@media (max-width: 920px)": {
                                width: '8rem',
                            },
                        })}>
                            <img
                                className={css({
                                    width: '100%',
                                })}
                                src={Logo}
                                alt="logo"
                            />
                            <h1 className={css({
                                textTransform: "uppercase",
                                textAlign: "center",
                                fontFamily: "'Exo 2' !important",
                                color: "#fff",
                                fontSize: "0.813rem",
                                fontWeight: "600",
                                "@media (max-width: 920px)": {
                                    fontSize: '.65rem',
                                }
                            })}>Expertise en recrutement</h1>
                        </Link>

                        <div className={css({
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: 'center',
                            gap: "3rem",
                            lgDown: {
                                gap: "1.5rem",
                            }
                        })}>
                            {(process.env.GATSBY_NO_PROFILES === 'true') ? null : (
                                <Link to={"/recrutement/profils"} className={css({
                                    position: 'relative',
                                    _after: {
                                        content: '""',
                                        position: 'absolute',
                                        bottom: "-.5rem",
                                        left: 0,
                                        width: contentPath.includes("/recrutement/profils") ? "calc(100% + .5rem)" : "0",
                                        transform: 'translateX(-0.25rem)',
                                        height: '0.2rem',
                                        backgroundColor: '#FFF',
                                        transition: 'width 0.3s',
                                        borderRadius: "1rem",
                                    },
                                    _hover: {
                                        _after: {
                                            width: 'calc(100% + .5rem)',
                                        },
                                    },
                                })}>
                                    <p className={css({
                                        color: "#FFF",
                                        fontWeight: "600",
                                        fontSize: "1.125rem",
                                        lineHeight: "1",
                                        textTransform: "uppercase",
                                        fontFamily: "'Exo 2' !important",
                                        lgDown: {
                                            fontSize: "1rem",
                                        }
                                    })}>Nos profils</p>
                                </Link>
                            )}
                            <Link to={"/recrutement/contact"} className={css({
                                position: 'relative',
                                _after: {
                                    content: '""',
                                    position: 'absolute',
                                    bottom: "-.5rem",
                                    left: 0,
                                    width: contentPath.includes("/recrutement/contact") ? "calc(100% + .5rem)" : "0",
                                    transform: 'translateX(-0.25rem)',
                                    height: '0.2rem',
                                    backgroundColor: '#FFF',
                                    transition: 'width 0.3s',
                                    borderRadius: "1rem",
                                },
                                _hover: {
                                    _after: {
                                        width: 'calc(100% + .5rem)',
                                    },
                                },
                            })}>
                                <p className={css({
                                    color: "#FFF",
                                    fontWeight: "600",
                                    fontSize: "1.125rem",
                                    lineHeight: "1",
                                    textTransform: "uppercase",
                                    fontFamily: "'Exo 2' !important",
                                    lgDown: {
                                        fontSize: "1rem",
                                    }
                                })}>Contact</p>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav >
    )
}
