import React, { useState, useEffect } from 'react'
import { css } from '../../styled-system/css'
import "../styles/keyframes.css"

const ScrollToTop = ({ color }: { color: string }) => {
    const [isVisible, setIsVisible] = useState(false);

    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    const checkScrollTop = () => {
        if (!isVisible && window.pageYOffset > 400) {
            setIsVisible(true);
        } else if (isVisible && window.pageYOffset <= 400) {
            setIsVisible(false);
        }
    }

    useEffect(() => {
        window.addEventListener("scroll", checkScrollTop);
        return () => {
            window.removeEventListener("scroll", checkScrollTop);
        };
    }, [isVisible]);

    return (
        <div className={css({
            position: "fixed",
            bottom: "4rem",
            right: "1.5rem",
            cursor: "pointer",
            rotate: "180deg",
            "& span": {
                display: "block",
                width: "1.5rem",
                height: "1.5rem",
                borderBottom: "0.225rem solid",
                borderRight: "0.225rem solid",
                rotate: "45deg",
                margin: "-10px",
                animation: "scrollTop 2s infinite",
                "&:nth-child(2)": {
                    animationDelay: "-0.2s",
                },
                "&:nth-child(3)": {
                    animationDelay: "-0.4s",
                }
            }
        })} onClick={handleScrollToTop} hidden={!isVisible}>
            <span style={{
                borderColor: color
            }}></span>
            <span style={{
                borderColor: color
            }}></span>
            <span style={{
                borderColor: color
            }}></span>
        </div>
    );
}

export default ScrollToTop;