import React from 'react'
import { ButtonVariants, buttonRecipe } from '../../theme/recipes/button.recipe'
import {
  ButtonHTMLAttributes,
  ForwardedRef,
  PropsWithChildren,
  forwardRef,
} from 'react'
import { cx } from '../../../styled-system/css'

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variants: ButtonVariants
  href?: string
}

export const Button = forwardRef(
  (
    props: PropsWithChildren<ButtonProps>,
    ref: ForwardedRef<HTMLButtonElement>
  ) => {
    const { children, variants, className, ...rest } = props

    return (
      <button
        ref={ref}
        className={cx(
          buttonRecipe({
            visual: variants?.visual,
            format: variants?.format,
          }),
          className
        )}
        {...rest}
      >
        {children}
      </button>
    )
  }
)
