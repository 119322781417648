import React, { useEffect, useState } from 'react';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import { useLocation } from '@reach/router';
import { css } from '../../styled-system/css';
import { Button } from './photographie/Button';

const CookieConsent = ({ isRecrutement }: { isRecrutement: boolean }) => {
    const [isAccepted, setIsAccepted] = useState<boolean | null>(null);
    const location = useLocation();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const storedValue = localStorage.getItem('rgpd-accepted');
            if (storedValue !== null) {
                setIsAccepted(storedValue === 'true');
            } else {
                setIsAccepted(false);
            }
        }

        if (isAccepted) {
            initializeAndTrack(location);
        }
    }, []);

    const handleAccept = () => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('rgpd-accepted', 'true');
        }
        setIsAccepted(true);
        initializeAndTrack(location);
    };

    const handleDecline = () => {
        if (typeof window !== 'undefined') {
            localStorage.setItem('rgpd-accepted', 'false');
        }
        setIsAccepted(false);
    };
    if (isAccepted !== null) {
        return null;
    }
    return (
        <div>
            <div className={css({
                padding: "0.5rem 2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                position: "fixed",
                bottom: "0",
                left: "0",
                width: "100%",
                backgroundColor: isRecrutement ? "#171F3D" : "#000",
                zIndex: "999999",
                mdDown: {
                    flexDirection: "column",
                    textAlign: "center",
                    gap: "1rem",
                    paddingTop: "1rem",
                }
            })}>
                <p className={css({
                    fontSize: "1.125rem",
                    color: isRecrutement ? "#fff" : "#D9C58B"
                })}>Nous utilisons des cookies pour améliorer votre expérience sur notre site.</p>
                <div className={css({
                    padding: "0.5rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: "1.5rem",
                    mdDown: {
                        padding: "0",
                        marginBottom: "0.5rem"
                    }
                })}>
                    <Button variants={{ visual: isRecrutement ? "primary" : "digit", format: "normal" }} onClick={handleDecline}>Refuser</Button>
                    <Button variants={{ visual: isRecrutement ? "primary" : "digit", format: "normal" }} onClick={handleAccept}>Accepter</Button>
                </div>
            </div>
        </div>
    );
};

export default CookieConsent;
