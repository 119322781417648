import { cva, RecipeVariantProps } from '../../../styled-system/css'

export const buttonRecipe = cva({
  base: {
    rounded: '5px',
    color: 'white',
    overflow: 'hidden',
    pos: 'relative',
    transition: '0.3s',
    fontSize: '13px',
    padding: '13px 23px !important',
    _active: {
      _enabled: {
        scale: '0.95',
      },
    },

    _enabled: {
      cursor: 'pointer',
    },
    _disabled: {
      filter: 'grayscale(1)',
    },
  },

  variants: {
    visual: {
      primary: {
        bg: '#007bff',
        _hover: {
          _enabled: {
            bg: '#0046FF',
          },
        },
      },
      secondary: {
        border: '1px solid black',
        color: 'black',
        _hover: {
          _enabled: {
            bg: 'black',
            color: 'white',
          },
        },
      },
      danger: {
        bg: '#dc3545',
        _hover: {
          _enabled: {
            bg: '#741C24',
          },
        },
      },
      success: {
        bg: '#279C42',
        _hover: {
          _enabled: {
            bg: '#1E7B33',
          },
        },
      },
      warn: {
        bg: '#B98D0A',
        _hover: {
          _enabled: {
            bg: '#86670A',
          },
        },
      },
      digit: {
        bg: '#D9C58B',
        color: 'black',
        boxShadow: '0px 6px 18px -9px #000000',
        fontWeight: '600',
        _hover: {
          _enabled: {
            bg: '#BDAB78',
          },
        },
      },
    },

    format: {
      square: {
        px: '7px',
        py: '7px',
      },

      normal: {
        px: '30px',
        py: '15px',
      },
    },
  },
})

export type ButtonVariants = RecipeVariantProps<typeof buttonRecipe>
